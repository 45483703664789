import {
  Radio as RadioMui,
  RadioGroup as RadioGroupMui,
  RadioProps as MuiRadioProps,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

export const MuiRadio = ({ ...rest }: MuiRadioProps) => <RadioMui {...rest} />;

export const MuiRadioGroup = ({ ...rest }: MuiRadioGroupProps) => <RadioGroupMui {...rest} />;
