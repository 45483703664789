import { CardContent, CardHeader, Card } from '@timelog/ui-library';
import { ReactNode } from 'react';
// import { Info, MoreVert } from '@mui/icons-material';

interface IReportingWidgetCard {
  name: string;
  children?: ReactNode;
}

export const ReportingWidgetCard = ({ name: title, children }: IReportingWidgetCard) => (
  <Card className={`Card-${title}`} variant="outlined" sx={{ height: '100%' }}>
    <CardHeader
      title={title}
      // DRO: Will leave the action here for a while since we are going to have this in the next iteration.
      // action={
      //   <>
      //     <MuiIconButton size="small" aria-label="info">
      //       <Info />
      //     </MuiIconButton>
      //     <MuiIconButton size="small" aria-label="action">
      //       <MoreVert />
      //     </MuiIconButton>
      //   </>
      // }
    />
    <CardContent>{children}</CardContent>
  </Card>
);

export default ReportingWidgetCard;
