import { useContext } from 'react';
import { ContextMenu } from 'src/components/ui-components/ContextMenu';
import { Modal, ModalContext } from 'src/components/ui-components/Modal';
import PreviewFeatures from 'src/screens/PreviewFeatures';
import { useTranslation } from 'react-i18next';
import FeatureControl from 'src/components/FeatureControl';
import HelpButton from 'src/components/HelpButton';
import { useGetPreviewFeaturesCount } from 'src/apis/userPreviewFeaturesAPI';
import styles from './PreviewFeaturesButton.module.scss';

const previewFeatureButtonText = (translationText = '', previewCount = '') =>
  `${translationText} ${previewCount}`?.trim();

export const previewFeatureButtonTextForText = previewFeatureButtonText;

const PreviewFeatureTriggerButton = () => {
  const { t } = useTranslation('header');
  const { previewFeaturesCount } = useGetPreviewFeaturesCount();
  const { openModal } = useContext(ModalContext);

  return (
    <ContextMenu.Item data-automation-id="HeaderPreviewFeatures" onSelect={openModal}>
      {previewFeatureButtonText(t('UserMenuPreviewFeaturesLink'), previewFeaturesCount)}
    </ContextMenu.Item>
  );
};

const PreviewFeaturesButton = () => {
  const { t } = useTranslation('header');

  return (
    <FeatureControl indexControlName="UserPreviewFeatures">
      <Modal>
        <PreviewFeatureTriggerButton />
        <Modal.Dialog data-automation-id="ModalPreviewFeatures" size="medium">
          <Modal.Header
            heading={t('UserMenuPreviewFeaturesLink')}
            closeText={t('UserMenuPreviewFeaturesCloseModal')}
          >
            <FeatureControl indexControlName="Users">
              <HelpButton />
            </FeatureControl>
          </Modal.Header>
          <div className={styles.PreviewFeaturesWrapper}>
            <PreviewFeatures />
          </div>
        </Modal.Dialog>
      </Modal>
    </FeatureControl>
  );
};

export default PreviewFeaturesButton;
