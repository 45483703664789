import DialogMui from '@mui/material/Dialog';
import DialogActionsMui from '@mui/material/DialogActions';
import DialogContentMui from '@mui/material/DialogContent';
import DialogContentTextMui from '@mui/material/DialogContentText';
import DialogTitleMui from '@mui/material/DialogTitle';
import {
  DialogProps,
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogTitleProps,
} from '@mui/material';

export const Dialog = ({ ...rest }: DialogProps) => <DialogMui {...rest} />;
export const DialogActions = ({ ...rest }: DialogActionsProps) => <DialogActionsMui {...rest} />;
export const DialogContent = ({ ...rest }: DialogContentProps) => <DialogContentMui {...rest} />;
export const DialogContentText = ({ ...rest }: DialogContentTextProps) => (
  <DialogContentTextMui {...rest} />
);
export const DialogTitle = ({ ...rest }: DialogTitleProps) => <DialogTitleMui {...rest} />;
