import { IconButton as IconButtonMui, IconButtonProps } from '@mui/material';
import { forwardRef } from 'react';

type IconButtonRef = HTMLButtonElement;

// There is a bug with css being added as required prop when building
// ui lib bundle. Hopefully moving mui to web-app will fix this, so
// we can remove this interface.
interface IconButtonPropsWithoutCss extends Omit<IconButtonProps, 'css'> {}

export const MuiIconButton = forwardRef<IconButtonRef, IconButtonPropsWithoutCss>(
  ({ ...rest }, ref) => <IconButtonMui ref={ref} {...rest} />,
);
