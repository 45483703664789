import { createTheme } from '@mui/material';
import scssTypography from '../styles/variables/typography.module.scss';

export const timelogTheme = createTheme({
  palette: {
    primary: {
      main: '#BF2574',
      contrastText: '#fff',
    },
    secondary: {
      main: '#BF2574',
      contrastText: '#fff',
    },
    error: {
      main: '#C92726',
      contrastText: '#fff',
    },
    warning: {
      main: '#DA8B01',
      contrastText: '#fff',
    },
    info: {
      main: '#3A66D5',
      contrastText: '#fff',
    },
    success: {
      main: '#007A00',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: scssTypography.fontFamily,
    fontSize: parseInt(scssTypography.fontSizeBody, 10),
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          component: 'h2',
          fontSize: parseInt(scssTypography.fontSizeSubHeading, 10),
          fontWeight: parseInt(scssTypography.fontWeightBold, 10),
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: scssTypography.fontFamily,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
        spacing: 1,
      },
    },
  },
});
