import { FC, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Grid.module.scss';

interface GridInterface {
  (props: GridProps): JSX.Element;
  Item: FC<GridItemProps>;
}

// Grid container interface
export interface GridProps extends HTMLAttributes<HTMLElement> {
  /** Limit width of container to 1400px (used when displaying rows of cards) */
  limitWidth?: boolean;
  /** Add vertical spacing between cols */
  verticalGutter?: boolean;
  /** Should content be bottom aligned */
  alignContentBottom?: boolean;
}

// Grid Container
export const Grid: GridInterface = ({
  limitWidth,
  verticalGutter,
  alignContentBottom,
  ...props
}) => (
  <div
    className={classNames(styles.grid, {
      [styles.limitWidth]: limitWidth,
    })}
    role="grid"
  >
    <div
      className={classNames(styles.inner, {
        [styles.innerVerticalGutter]: verticalGutter,
        [styles.innerAlignBottom]: alignContentBottom,
      })}
      {...props}
    />
  </div>
);

export type TColSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

// Grid row interface
// Grid Item interface
export interface GridItemProps extends HTMLAttributes<HTMLElement> {
  /** Grid col content */
  children?: ReactNode;
  /** How many cols (default) */
  colSpan?: TColSpan;
  /** How many cols (Screens larger than 576px wide) */
  colSpanSmall?: TColSpan;
  /** How many cols (Screens larger than 768px wide) */
  colSpanMedium?: TColSpan;
  /** How many cols (Screens larger than 1024px wide) */
  colSpanLarge?: TColSpan;
  /** How many cols (Screens larger than 1200px wide) */
  colSpanXLarge?: TColSpan;
  /** Move col by X cols */
  colPush?: TColSpan;
  /** Move col by X cols on screens larger than 1200px */
  colPushXLarge?: TColSpan;
  /** Force a new row of cols BEFORE this col */
  forceNewRow?: boolean;
}

Grid.Item = ({
  colSpan = 1,
  colSpanSmall = 1,
  colSpanMedium = 1,
  colSpanLarge = 1,
  colSpanXLarge = 1,
  colPush = 1,
  colPushXLarge = 1,
  forceNewRow,
  ...props
}) => (
  <>
    {!!forceNewRow && <div className={styles.gridClearFix} />}
    <div
      className={classNames(styles.gridItem, {
        [styles[`gridItem_${colSpan}`]]: colSpan,
        [styles[`gridItemSm_${colSpanSmall}`]]: colSpanSmall,
        [styles[`gridItemMd_${colSpanMedium}`]]: colSpanMedium,
        [styles[`gridItemLg_${colSpanLarge}`]]: colSpanLarge,
        [styles[`gridItemXl_${colSpanXLarge}`]]: colSpanXLarge,
        [styles[`gridItemPush_${colPush}`]]: colPush,
        [styles[`gridItemXlPush_${colPushXLarge}`]]: colPushXLarge,
      })}
      {...props}
    />
  </>
);

export default Grid;
