import CardMui from '@mui/material/Card';
import CardActionAreaMui from '@mui/material/CardActionArea';
import CardActionsMui from '@mui/material/CardActions';
import CardContentMui from '@mui/material/CardContent';
import CardHeaderMui from '@mui/material/CardHeader';
import CardMediaMui from '@mui/material/CardMedia';
import {
  CardProps,
  CardActionAreaProps,
  CardActionsProps,
  CardContentProps,
  CardHeaderProps,
  CardMediaProps,
} from '@mui/material';

export const Card = ({ ...rest }: CardProps) => <CardMui {...rest} />;

export const CardActionArea = ({ ...rest }: CardActionAreaProps) => <CardActionAreaMui {...rest} />;
export const CardActions = ({ ...rest }: CardActionsProps) => <CardActionsMui {...rest} />;
export const CardContent = ({ ...rest }: CardContentProps) => <CardContentMui {...rest} />;
export const CardHeader = ({ ...rest }: CardHeaderProps) => <CardHeaderMui {...rest} />;
export const CardMedia = ({ ...rest }: CardMediaProps) => <CardMediaMui {...rest} />;
