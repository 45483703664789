import { Navigate, Routes, Route } from 'react-router-dom';
import { useGetDesignSevenPages } from 'src/apis/pagesAPI/designSevenAPI';
import { redirectObject } from './redirectObject';

export default () => {
  const { designSevenPageList, isLoading } = useGetDesignSevenPages();
  if (isLoading) return null;

  return (
    <Routes>
      {Object.entries(redirectObject).map(([key, { newPath, oldPaths }]) => {
        const isContainingPageIdentifier = designSevenPageList?.find(
          ({ pageIdentifier }) => pageIdentifier === key,
        );
        return isContainingPageIdentifier ? (
          oldPaths.map((oldPath) => (
            <Route
              path={oldPath}
              // eslint-disable-next-line react/no-unstable-nested-components
              element={<Navigate replace to={newPath as string} />}
              key={`routeToNewPath${key}`}
            />
          ))
        ) : (
          <Route
            path={newPath as string}
            // eslint-disable-next-line react/no-unstable-nested-components
            element={<Navigate replace to={oldPaths[0]} />}
            key={`routeToOldPath${key}`}
          />
        );
      })}
    </Routes>
  );
};
